import React from "react";

import { Base, Basic, Campaign, Footer,  Head } from "../components";

const Learn = () => {
  return (
    <>
    <Head/>
      <div className="heading-image">
        <div className="heading-title">
          <h1>Help Center</h1>
        </div>
      </div>
<Base/>
     
      <Campaign />
      <div className="basic-main">
        <Basic
          title="Donations and Withdrawals"
          sub="Stripe Fees (UK Accounts)"
          icon="fa fa-file"
          sub1="How Long Do Withdrawals Take"
          sub2="How Are Payments Processed"
          sub3="Private or Anonymous Donations"
        />
        <Basic
          title="Fundraising Basics"
          sub="Stripe Fees (UK Accounts)"
          sub1="How Long Do Withdrawals Take"
          sub2="How Are Payments Processed"
          sub3="Private or Anonymous Donations"
          icon="fa fa-file"
        />
        <Basic
          title="Manage Your Contribution"
          sub="Stripe Fees (UK Accounts)"
          sub1="How Long Do Withdrawals Take"
          sub2="How Are Payments Processed"
          sub3="Private or Anonymous Donations"
          icon="fa fa-file"
        />
      </div>

      <Footer />
    </>
  );
};

export default Learn;
