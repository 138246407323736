import React from "react";

export default function Position(props) {
  const { img, title, sub } = props;

  return (
    <>
      <div className="main-position">
        <img src={img} alt={title}></img>
        <h5>{title}</h5>
        <p>
          {sub}
          <span className="main-icon">
            <i className="fa fa-arrow-right"> </i>{" "}
          </span>
        </p>
      </div>
    </>
  );
}
