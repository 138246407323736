import React from "react";

export default function Basic(props) {
  const { title, sub, sub1, sub2, sub3 } = props;
  return (
    <>
      <div className="basic-info">
        <h1>{title}</h1>
        <ul>
          <li>
            <span className="campaign-icons">
              {" "}
              <i className="fa fa-file"></i>
            </span>{" "}
            {sub}
          </li>
          <hr className="campaign-map"></hr>
          <li>
            <span className="campaign-icons">
              {" "}
              <i className="fa fa-file"></i>
            </span>{" "}
            {sub1}
          </li>
          <hr className="campaign-map"></hr>
          <li>
            <span className="campaign-icons">
              {" "}
              <i className="fa fa-file"></i>
            </span>{" "}
            {sub2}
          </li>
          <hr className="campaign-map"></hr>
          <li>
            <span className="campaign-icons">
              {" "}
              <i className="fa fa-file"></i>
            </span>{" "}
            {sub3}
          </li>
          <hr className="campaign-map"></hr>
        </ul>
      </div>
    </>
  );
}
