import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import { Browse, Home, Learn, Login, SignUp } from "./pages";

export const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/browse" element={<Browse />} />
          <Route path="/learn" element={<Learn />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
      {/* <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/browse" element={<Browse />} />
      </Routes> */}
    </>
  );
};
export default App;
