import React from "react";
import { ROOM } from "../../assets";

export default function Investment(props) {
  return (
    <>
      <div className="invest-area">
        <img src={props.image} alt={props.title}></img>
        <div className="invest-rotet">EIS</div>
        <div className="invest-hook">
          <img src={ROOM} alt={props.title}></img>
        </div>
        <div className="invest-hold">
          <h2>{props.title}</h2>
          <h6>
            <span className="invest-icon">
              <i className="fa fa-map-marker-alt"></i>
            </span>
            {props.head}
          </h6>
          <hr></hr>
        </div>

        <div className="invest-mark">
          <div className="invest-look">
            <h5>$0.00 USD</h5>
            <p>Raised</p>
          </div>
          <div className="invest-look">
            <h5>
              $0.00 <br></br> USD
            </h5>
            <p>Goal</p>
          </div>
          <div className="invest-look">
            <h5>309</h5>
            <p>Raised</p>
          </div>
        </div>
      </div>
    </>
  );
}
