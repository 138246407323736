import React from "react";
import { Footer, Head, Quick } from "../components";

const Login = () => {
  return (
    <>
  <Head/>
     
      <Quick />

      <Footer />
    </>
  );
};

export default Login;
