import React from "react";

export default function Section() {
  return (
    <>
      <div className="section-image">
        <div className="section-row">
          <h1>It's Easy to Get Funded with Real Estate</h1>
          <p>
            Real Estate Get an indication of approval after a short, 4 step
            application process. We need property address, financials, your
            experience and <br /> contact information so we can follow up. Know
            immediately if we can help you fund your next property.
          </p>
          <button className="section-btn">Get Invest</button>
        </div>
      </div>
    </>
  );
}
