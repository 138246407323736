import React from "react";

export default function Contact() {
  return (
    <>
      <div className="contact-info">
        <div className="contact-sub">
          <h1>Stay connected with Real Estate</h1>
          <p>
            Receive new offerings, events, news and more directly into your
            inbox.
          </p>
        </div>
        <div className="contact">
          <div className="contact-input">
            <input type="email" placeholder="Enter Your Email" />
            <button type="Submit">JOIN NOW</button>
          </div>
        </div>
      </div>
    </>
  );
}
