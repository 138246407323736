import React from "react";
import { useNavigate } from "react-router-dom";

export default function Symbol() {
  const navigate = useNavigate();
  return (
    <>
      <div className="contact-main">
        <div className="contact-symbol">
          <h1>Sign Up</h1>
          <h6>
            Already have an account?{" "}
            <span onClick={() => navigate("/login")} className="form-text">
              Log In
            </span>{" "}
          </h6>
          <form method="post">
            <div className="txt-field">
              <input type="text" required />
              <label>Full Name</label>
            </div>
            <div className="txt-field">
              <input type="password" required />
              <label>Phone</label>
            </div>

            <div className="txt-field">
              <input type="password" required />
              <label>Email Address</label>
            </div>
            <div className="txt-field">
              <input type="password" required />
              <label>Retype Email</label>
            </div>
            <div className="txt-field">
              <input type="password" required />
              <label>Password</label>
            </div>
            <div className="txt-field">
              <input type="password" required />
              <label>Retype Password</label>
            </div>
            <div className="input-file">
              <h5>
                {" "}
                By clicking Sign Up for Equity in Frensh, you agree to our{" "}
                <span className="form-term">Terms Of Use .</span>
              </h5>
            </div>
            <div className="login-file-1">
              <button className="login-button">Sign Up</button>
            </div>
            <h5 className="form-head">
              <span>or</span>
            </h5>
            <div className="input-head">
              <h2>
                <span className="input-icon">
                  {" "}
                  <i className="fas fa-sign-in-alt"></i>
                </span>
                Social sign up
              </h2>
            </div>
            <h6 className="input-head-2">
              We will never post anything without permission
            </h6>
          </form>
        </div>
      </div>
    </>
  );
}
