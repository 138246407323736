import React from "react";
import Carousel from "react-elastic-carousel";
import {
  BULB,
  CATEGORIES1,
  CATEGORIES2,
  CATEGORIES3,
  COIN,
  COLOR,
  CREATE1,
  CREATE2,
  CREATE3,
  GOAL,
  INVES,
  MSA,
  ROOM,
} from "../assets";
import {
  Card,
  Categories,
  Contact,
  Footer,
  Header,
  Investment,
  Section,
} from "../components";
import Position from "../components/Main/Position";

const Home = () => {
  return (
    <>
      <div className="containor-field">
     
        <Header />
        <div className="member">
      <Carousel itemsToShow={1}>
        <div className="image-field">
          <div className="main-containor">
            <h1>
              Welcome to the future of <br />
              real estate investing.
            </h1>
            <p>Simple, low-cost, and more powerful than ever.</p>
            <button className="btn-1">Get Started</button>
          </div>
        </div>
        <div className="image-field-2">
          <div className="main-containor">
            <h1>
              Welcome to the future of <br />
              real estate investing.
            </h1>
            <p>Simple, low-cost, and more powerful than ever.</p>
            <button className="btn-1">Get Started</button>
          </div>
        </div>
      </Carousel>
      </div>
        <div className="card-info">
          <div className="card-containor">
            <h1>What Is Real Estate Crowdfunding?</h1>
            <p>
              Our model, technology, and team of in-house experts make us the
              most trusted platform in the crowdfunding industry.
              <br /> We leverage all the tools in our power to amplify
              Campaigns, so you can raise maximum awareness
            </p>
          </div>
          <div className="card-center">
            <Card
              img={BULB}
              title="Find Entrepreneurs"
              des="Discover startups that are raising capital online."
            />
            <Card
              img={COIN}
              title="Invest in Startups"
              des="Take a look at live investment opportunities today, and give yourself a feel-good factor."
            />
            <Card
              img={INVES}
              title="Follow Your Investment"
              des="Manage & track your investments from your investor dashboard."
            />
          </div>
        </div>
        <div className="investment-containor">
          <h1>Current Investment Opportunities</h1>
          <div className="invest-field">
            <Investment
              img={ROOM}
              image={MSA}
              title="Atlanta MSA Equity"
              head="Vadodara, India"
            />
            <Investment
              image={COLOR}
              title="Apartment Growth Debt"
              head="Vadodara, India"
            />
            <Investment
              image={COLOR}
              title="Apartment Growth Debt"
              head="Vadodara, India"
            />
          </div>
        </div>
        <Section />
        <div className="position-info">
          <h1>Categories</h1>
        </div>
        <div className="position-containor">
          <Position
            img={GOAL}
            title="Residential real estate"
            sub="See Residential real estate Listing"
          />
          <Position img={CATEGORIES1} title="Land" sub="See LandListing" />
          <Position
            img={CATEGORIES2}
            title="Commercial real estate"
            sub="See Residential real estate Listing"
          />
          <Position
            img={CATEGORIES3}
            title="Industrial real estate"
            sub="See Residential real estate Listing"
          />
        </div>

        <div className="categories-info">
          <h1>How to Raise Capital</h1>
          <p>
            Our model, technology, and team of in-house experts make us the most
            trusted platform in the crowdfunding industry.
            <br /> We leverage all the tools in our power to amplify Campaigns,
            so you can raise maximum awareness
          </p>
        </div>
        <div className="categories-class">
          <Categories
            img={CREATE1}
            title="Create Campaign"
            heading="So you've got an idea? Draft your appealing 'Campaign' & submit."
          />
          <Categories
            img={CREATE2}
            title="Launch Campaign"
            heading="Tell your crowd about Campaign and share via social networking channels."
          />
          <Categories
            img={CREATE3}
            title="Promote to Network"
            heading="Tell your crowd about Campaign and share via social networking channels."
          />
        </div>
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
