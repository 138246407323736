import React from "react";
import { COLOR, MSA, ROOM } from "../assets";
import { Footer,  Head,   Investment } from "../components";
const Browse = () => {
  return (
    <>
     <Head/>
      <div className="head-field">
        <div className="head-area">
          <h1>
            All Categories{" "}
            <span className="head-icon">
              <i class="fa fa-caret-down"></i>
            </span>
          </h1>
          <hr className="head-map"></hr>
          <h4>
            Sorted By{" "}
            <span className="head-info">
              Latest Campaign{" "}
              <span className="head-icon">
                <i class="fa fa-caret-down"></i>
              </span>
            </span>{" "}
            <hr className="head-map-1"></hr>
          </h4>
          <h5>Advance Search</h5>
        </div>
      </div>
      <div className="invest-field-1">
        <div className="invest-field">
          <Investment
            img={ROOM}
            image={MSA}
            title="Atlanta MSA Equity"
            head="Vadodara, India"
          />
          <Investment
            image={COLOR}
            title="Apartment Growth Debt"
            head="Vadodara, India"
          />
          <Investment
            image={COLOR}
            title="Apartment Growth Debt"
            head="Vadodara, India"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Browse;
