import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {   YARD3 } from "../../assets";

export default function Header() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const navigate = useNavigate();
  const Navbar = [
    {
      id: "1",
      name: "Browse",
      onClick: () => {
        navigate("/browse");
      },
    },
    {
      id: "2",
      name: "Create a Campaign",
    },
    {
      id: "3",
      name: "Learn",
      onClick: () => {
        navigate("/learn");
      },
    },
    {
      id: "4",
      name: "Login",
      onClick: () => {
        navigate("/login");
      },
    },
  ];
  return (
    <>
     <div className='bar-item'>
      <div className='bar-image' style={{position:"relative",height:"70px" ,width:"10em"}}>
      
          <span><img  onClick={() => navigate("/")} src={YARD3} alt="logo" layout='fill' objectFit="contain" /></span>
       
      </div>

      <div className='bar-flex'>
     <span onClick={()=>setShowMediaIcons(!showMediaIcons)}> <i className="fa fa-bars"></i></span>
      </div>
    </div>

    <div className={`${showMediaIcons?"menu-nav-link":'menu-link-1'}`}>
    
    
    <div className='bar-menu'>
      
    <ul className='menu'>
     
     <span onClick={() => navigate("/browse")}>  BROWSE</span>  
    
    </ul>
    <ul className='menu'>
     
     <span onClick={() => navigate("/login")}>CREATE A CAMPAIGN</span>  
     
    </ul>
    <ul className='menu'>
    
    <span onClick={() => navigate("/learn")}>LEARN</span>  
   
   </ul>
  
    <ul className='menu'>
    
      <span   onClick={() => navigate("/login")}>LOG IN</span>  
      
    </ul>
   
    <ul className='menu'>
      
      <span onClick={() => navigate("/signup")}> SIGN UP</span>  
      
    </ul>
   
  
  
    
   
     </div>
    </div>
      <div className="nav-info">
        <nav className="navbar">
          <div className="hamburger-flex">
            <div className="logo">
              <img onClick={() => navigate("/")} src={YARD3} alt="logo"></img>
            </div>
           
          </div>
        
            <div
              className= "menu-link"
              
            >
              <ul className="menu">
                {Navbar.map((item) => {
                  return (
                    <li key={item.id}>
                      <div onClick={item.onClick}>{item.name}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
         

          <div className="about-button">
            <button onClick={() => navigate("/signup")} className="about-btn">
              Sign Up
            </button>
          </div>
        </nav>
      </div>
     
    </>
  );
}
