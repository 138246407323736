import React from "react";

export default function Element() {
  return (
    <>
      <div className="element-section">
        <h1>Forgot your password</h1>
        <div className="element-contact">
          <div className="txt-field">
            <input type="text" required />
            <label>Email</label>
          </div>
          <div className="element-tag">
            <div className="element-style">
              <h5>Back to Login</h5>
            </div>
            <div className="element-method">
              <button className="element-methos">Reset Password</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
