import React, { useState } from "react";
import { TV } from "../../assets";
import { useNavigate } from "react-router-dom";
import Element from "./Element";

export default function Quick() {
  const [showMedia, setMedia] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className="contact-main">
        <div className="contact-info-2">
          <img src={TV} alt="tv"></img>
        </div>
        {!showMedia ? (
          <>
            <div className="contact-text">
              <h1>Log In</h1>
              <h6>
                Don't have an account?
                <span
                  onClick={() => navigate("/signup")}
                  className="contact-sign"
                >
                  {" "}
                  Sign Up
                </span>
              </h6>
              <form method="post">
                <div className="txt-field">
                  <input type="text" required />
                  <label>Email</label>
                </div>
                <></>
                <div className="txt-field">
                  <input type="password" required />
                  <label>Password</label>
                </div>
                <div className="login-text">
                  <div className="login-file">
                    <button className="login-button">LogIn</button>
                  </div>

                  <h5 onClick={() => setMedia((prev) => !prev)}>
                    I forgot my password
                  </h5>
                </div>
                <h5 className="form-head">
                  <span>or</span>
                </h5>

                <div className="social-login-wrapper"></div>

                <div className="input-head">
                  <h2>
                    <span className="input-icon">
                      {" "}
                      <i className="fas fa-sign-in-alt"></i>
                    </span>
                    Social log In
                  </h2>
                </div>
                <h6 className="input-head-2">
                  We will never post anything without permission
                </h6>
              </form>
            </div>
          </>
        ) : (
          <Element />
        )}
      </div>
    </>
  );
}
