import React from "react";

export default function Base() {
 

  return (
    <>
      <div className="base-contain">
   <div className="base-flex">
       <div className='base-design'>
   <i className="fa fa-lightbulb-o"></i> 
   </div>
   <div className='base-design'>
    <h1>Knowledge Base</h1>
          <h5>25Articles/7Categories</h5>
         </div>
         </div>
       <div className="base-flex-2">
       <div className='base-design'>
     <span className='base-icon'>  <i className="fa fa-question"></i></span>
   </div>
   <div className='base-design'>
    <h1>Knowledge Base</h1>
          <h5>25Articles/7Categories</h5>
         </div>
     
           </div>
       <div className="base-flex-3">
       <div className='base-design'>
       <span className='base-icon'><i className="fa fa-envelope"></i></span>
   </div>
   <div className='base-design'>
    <h1>Knowledge Base</h1>
          <h5>25Articles/7Categories</h5>
         </div>
     
        

    </div> 
   </div>
    </>
  );
}
