import React from "react";

export default function Categories(props) {
  const { img, title, heading } = props;
  return (
    <>
      <div className="categories-main">
        <img src={img} alt={title}></img>
        <h1>{title}</h1>
        <p>{heading}</p>
      </div>
    </>
  );
}
