import React from "react";

export default function Campaign() {
  return (
    <>
      <div className="campaign-main">
        <div className="campaign-info">
          <h1>Contributing to a Campaign</h1>

          <div className="campaign-head">
            <ul>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                How to Contribute Anonymously
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  <i className="fa fa-file"></i>
                </span>
                Common Contribution Errors
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                How to Check on Your Perks
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>{" "}
                </span>
                How to Upgrade Your Perk
              </li>
              <hr className="campaign-map"></hr>
            </ul>
          </div>
        </div>
        <div className="campaign-info">
          <h1>Creating a Campaign</h1>
          <div className="campaign-head">
            <ul>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                Demo
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                How To Add Media To Pitch Text
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                How to Go Live
              </li>
              <hr className="campaign-map"></hr>

              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>{" "}
                How to Upgrade Your Perk
              </li>
              <hr className="campaign-map"></hr>
            </ul>
          </div>
        </div>

        <div className="campaign-info">
          <h1>Creating a Campaign</h1>
          <div className="campaign-head">
            <ul>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                Demo
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                How To Add Media To Pitch Text
              </li>
              <hr className="campaign-map"></hr>
              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>
                How to Go Live
              </li>
              <hr className="campaign-map"></hr>

              <li>
                <span className="campaign-icons">
                  {" "}
                  <i className="fa fa-file"></i>
                </span>{" "}
                How to Upgrade Your Perk
              </li>
              <hr className="campaign-map"></hr>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
