import React from "react";

export default function card(props) {
  const { img, title, des } = props;

  return (
    <>
      <div className="main-card">
        <img src={img} alt={title}></img>
        <h2>{title}</h2>
        <h5>{des}</h5>
      </div>
    </>
  );
}
