import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer-section">
        <div className="footer-area">
          <h2>ABOUT</h2>
          <ul>
            <li>About us</li>
            <li>HelpCenter</li>
            <li>FAQ</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="footer-area">
          <h2>Quick Link</h2>
          <ul className="footer-target">
            <li>BankTerms and Condition</li>
            <li>Fees</li>
            <li>Press</li>
            <li>SideBar</li>
          </ul>
        </div>
        <div className="footer-area">
          <ul className="footer-field">
            <li>Career</li>
            <li>Help</li>
            <li>PrivacyPolicy</li>
          </ul>
        </div>
        <div className="footer-area">
          <div className="footer-text">
            <h2 className="footer-title">LET'S SOCIALIZE</h2>
            <div className="social-site">
              <i className="fa fa-facebook"></i>
              <i className="fa  fa-instagram"></i>
              <i className="fa  fa-whatsapp"></i>
              <i className="fa fa-youtube"></i>
              <i className="fa fa-linkedin"></i>
            </div>
            <input type="text" name="text" placeholder="English" />
            <span className="footer-code">
              {" "}
              <i className="fa fa-caret-down"></i>
            </span>
          </div>
        </div>
      </div>
      <hr className="footer-line"></hr>
      <div className="footer-bottom">
       <div className="footer-link">
       <p> © 2022 YardFundRaising All Rights Reserved.</p>
       </div>
       <div className="footer-start">
       <h6>Designed and Developed by <span className="footer-link-brand"><a href="https://searchingyard.com/"  target="_blank"   rel="noreferrer">SearchingYard SoftWare Private Limited</a></span></h6>
       </div>
      </div>
    </>
  );
}
