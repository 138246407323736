import React from "react";
import { Footer,  Head,   Symbol } from "../components";

const SignUp = () => {
  return (
    <>
   
<Head/>
      <Symbol />
      <Footer />
    </>
  );
};

export default SignUp;
